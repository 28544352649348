'use client';

import { AhafoodLogo } from '@ahamove/icons/order';
import { JSFunction } from '@ahamove/js-function';
import { cn } from '@ahm/common-helpers';
import { X } from 'lucide-react';
import { usePathname, useSearchParams } from 'next/navigation';
import classes from './main-layout.module.css';
import LoginButton from '@/components/fb-login-btn';
import { Link } from '@/i18n/routing';
import { useIsMobile } from '@/lib/is-mobile';

export default function MainHeader() {
  const isMobile = useIsMobile();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const isWebinapp = !!searchParams.get('webinapp');

  return (
    <header
      className={cn(
        pathname === '/store' || pathname === '/en/store'
          ? classes.headerStore
          : classes.header
      )}
    >
      {isWebinapp && isMobile ? (
        <div className="relative w-full gap-4 border-b border-neutral-15 py-3 text-center text-base font-bold">
          <X
            onClick={() => {
              JSFunction.call({ name: 'close' });
            }}
            className="absolute left-4 top-3"
          />
          Ahafood
        </div>
      ) : null}
      <div className="container mx-auto flex h-auto w-full max-w-screen-xl flex-col items-center justify-between gap-4 p-4 sm:flex-row md:h-24 md:gap-6 md:px-6">
        <div className="flex w-full flex-1 flex-row flex-nowrap items-center">
          <Link className="flex flex-none items-center justify-start" href="/">
            <AhafoodLogo className="h-10 w-36 sm:h-[64px] sm:w-[259px]" />
            <h1 className="sr-only">Ahamove</h1>
          </Link>
        </div>
        <div className="m-0 flex w-full flex-nowrap justify-end sm:w-3/5">
          <LoginButton />
        </div>
      </div>
    </header>
  );
}
