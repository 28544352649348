'use client';

import { ChevronDown, Search, X } from 'lucide-react';
import { useSearchParams } from 'next/navigation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import StoreCityIdDialog from '@/app/[locale]/(main)/store/components/store-city-id-dialog';
import { usePathname, useRouter } from '@/i18n/routing';
import { CITIES, useAiFoodOrderStore } from '@/store';

export default function HeaderFilter() {
  const searchParams = useSearchParams();
  const router = useRouter();
  const pathname = usePathname();

  const [isOpenSearchDialog, setIsOpenSearchDialog] = useState<boolean>(false);
  const [itemSearch, setItemSearch] = useState<string>(
    searchParams.get('q') ?? ''
  );
  const lat = searchParams.get('lat')
    ? parseFloat(searchParams.get('lat')!)
    : undefined;
  const lng = searchParams.get('lng')
    ? parseFloat(searchParams.get('lng')!)
    : undefined;

  const cityId = useAiFoodOrderStore.use.city_id();
  const address = useAiFoodOrderStore.use.address();
  const setAddress = useAiFoodOrderStore.use.setAddress();

  const selectedCity = useMemo(() => {
    return CITIES.find((c) => c.value === cityId);
  }, [cityId]);

  const addressSelected = useMemo(() => {
    if (cityId && !address) {
      return selectedCity?.label;
    }
    if (address && cityId) {
      return address.address;
    } else {
      return 'Nhập vị trí của bạn';
    }
  }, [address, cityId, selectedCity]);

  const debouncedSearch = useCallback(
    (value: string) => {
      const params = new URLSearchParams(searchParams);
      if (value) {
        params.delete('category');
        params.delete('campaign_id');
        params.set('q', value);
      } else {
        params.delete('q');
      }
      const newUrl = `${pathname}?${params.toString()}`;
      router.push(newUrl, { scroll: false });
    },
    [pathname, router, searchParams]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      debouncedSearch(itemSearch);
    }, 500);

    return () => clearTimeout(timer);
  }, [itemSearch, debouncedSearch]);

  useEffect(() => {
    if (!cityId && !!lat && !!lng) {
      setIsOpenSearchDialog(false);
    } else {
      setIsOpenSearchDialog(!cityId ? true : false);
    }
  }, [cityId, lat, lng]);

  useEffect(() => {
    setItemSearch(searchParams.get('q') ?? '');
  }, [searchParams]);

  return (
    <>
      <StoreCityIdDialog
        showCityPopup={isOpenSearchDialog}
        setShowCityPopup={setIsOpenSearchDialog}
        setAddress={setAddress}
        address={address}
      />
      <div className="flex w-full flex-col justify-end gap-3 md:flex-row md:gap-4">
        <div className="relative w-full md:max-w-80">
          {address ? (
            <Button
              variant="ghost"
              size="sm"
              className="absolute right-2 top-[14px] size-5 bg-white p-0 hover:bg-neutral-20 md:top-5"
              aria-label="Clear input"
              onClick={() => {
                setAddress(null);
              }}
            >
              <X className="size-5" />
            </Button>
          ) : null}
          <label className="absolute left-[18px] top-2 flex flex-none items-center text-xs font-medium text-neutral-70 md:top-3">
            Vị trí nhận món
            <ChevronDown className="ml-1" color="orange" size={16} />
          </label>
          <Input
            className="h-12 w-full justify-start truncate rounded-xl border-neutral-20 bg-neutral-10/30 pl-4 pr-10 text-sm font-bold hover:bg-neutral-30/30 md:h-16 md:max-w-96"
            value={addressSelected}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onChange={() => {}}
            aria-haspopup="listbox"
            aria-expanded={isOpenSearchDialog}
            style={{
              paddingTop: 25,
            }}
            onClick={() => setIsOpenSearchDialog(true)}
          />
        </div>

        <div className="relative flex w-full flex-row flex-nowrap items-center md:max-w-80">
          <Search className="absolute left-4 top-1/2 size-6 flex-none -translate-y-1/2" />
          {itemSearch ? (
            <Button
              variant="ghost"
              size="sm"
              className="absolute right-[15px] top-3 size-6 bg-white p-0 hover:bg-neutral-20 md:top-5"
              aria-label="Clear input"
              onClick={() => {
                setItemSearch('');
              }}
            >
              <X className="size-5" />
            </Button>
          ) : null}
          <Input
            value={itemSearch}
            onChange={(e) => setItemSearch(e.target.value)}
            className="inline-flex h-12 w-full items-center justify-start truncate whitespace-nowrap rounded-xl border border-neutral-20 bg-neutral-10/30 px-4 py-2 pl-12 text-sm font-bold ring-offset-background transition-colors hover:bg-neutral-30/30 hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 md:h-16 md:max-w-96 md:text-base"
            placeholder="Bạn muốn ăn gì?"
            type="search"
            id="search"
          />
        </div>
      </div>
    </>
  );
}
