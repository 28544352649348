'use client';

import { cn, session } from '@ahm/common-helpers';
import { X } from 'lucide-react';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { Controller, useForm, type SubmitHandler } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { CITIES } from '@/store';

type FormInputs = {
  restaurantName: string;
  phoneNumber: string;
  cityID: string;
};

export default function RestaurantRegistrationForm() {
  const pathname = usePathname();
  const [disableSticky, setDisableSticky] = useState<boolean>(
    !!session.getItem('acquire_visited') || true
  );

  useEffect(() => {
    const val = !!session.getItem('acquire_visited');
    setDisableSticky(val);
  }, []);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormInputs>();

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    console.log(data);
    handleHideAcquireForm();
  };

  const handleHideAcquireForm = () => {
    setDisableSticky(true);
    session.setItem('acquire_visited', 'true');
  };

  if (pathname.includes('store')) {
    return (
      <section
        className={cn('z-[10] my-10 bg-transparent px-4', {
          'sticky bottom-3': !disableSticky,
        })}
      >
        <div className="relative mx-auto max-w-screen-md space-y-3 rounded-xl bg-gradient-to-r from-secondary-70 to-secondary-50 p-4 md:px-6">
          {!disableSticky && (
            <button
              onClick={handleHideAcquireForm}
              className="absolute right-2 top-2 flex size-6 items-center rounded-full bg-white p-1 ring-muted md:right-4 md:top-4"
            >
              <X className="size-4" />
            </button>
          )}
          <div className="w-full text-center text-xl font-bold text-white md:text-2xl">
            <span>Nhà Hàng muốn tư vấn đăng ký</span>
            <span className="text-primary-50"> AhaFood.AI CHATBOT</span>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex-row items-start justify-between space-y-4"
          >
            <div className="grid grid-cols-1 gap-2 md:grid-cols-10">
              <div className="col-span-1 w-full md:col-span-3">
                <Label htmlFor="restaurantName" className="sr-only">
                  Tên nhà hàng
                </Label>
                <Input
                  id="restaurantName"
                  type="text"
                  className="md:h-12"
                  placeholder="Tên quán"
                  {...register('restaurantName', {
                    required: 'Tên quán là bắt buộc',
                  })}
                />
                {errors.restaurantName && (
                  <p className="mt-1 font-medium text-red-50">
                    {errors.restaurantName.message}
                  </p>
                )}
              </div>
              <div className="col-span-1 w-full md:col-span-3">
                <Label htmlFor="phoneNumber" className="sr-only">
                  Số Điện thoại
                </Label>
                <Input
                  id="phoneNumber"
                  type="tel"
                  className="md:h-12"
                  placeholder="Số Điện thoại"
                  {...register('phoneNumber', {
                    required: 'Số điện thoại là bắt buộc',
                    pattern: {
                      value: /^\d{10}$/,
                      message: 'Vui lòng nhập số điện thoại hợp lệ (10 chữ số)',
                    },
                  })}
                />
                {errors.phoneNumber && (
                  <p className="mt-1 font-medium text-red-50">
                    {errors.phoneNumber.message}
                  </p>
                )}
              </div>
              <div className="col-span-1 w-full md:col-span-2">
                <Controller
                  name="cityID"
                  control={control}
                  render={({ field }) => (
                    <Select
                      // defaultValue={CITIES[1].value}
                      onValueChange={field.onChange}
                      value={field.value}
                    >
                      <SelectTrigger
                        id="city_id"
                        className="w-full rounded-sm border border-neutral-30 bg-white px-3 py-2 text-neutral-60 shadow-sm md:h-12"
                      >
                        <SelectValue placeholder="Chọn khu vực" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {CITIES.map((city) => (
                            <SelectItem key={city.value} value={city.value}>
                              {city.label}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  )}
                />
                {errors.cityID && (
                  <p className="mt-1 text-sm text-red-50">
                    {typeof errors.cityID.message === 'string'
                      ? errors.cityID.message
                      : ''}
                  </p>
                )}
              </div>
              <Button
                type="submit"
                className="mt-2 h-12 w-full bg-primary-50 font-bold text-white first-line:col-span-1 hover:bg-primary-60 md:col-span-2 md:mt-0"
              >
                Gửi
              </Button>
            </div>
          </form>
        </div>
      </section>
    );
  }
}
